.customEditorWrapper {
  :global {
    .ql-editor {
      min-height: 100px;
    }
  }
}

.uploaderWrapper {
  position: relative;
  border: 2px dashed #d9d9d9;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  cursor: pointer;
  

.uploaderWrapper {
  position: relative;
  display: inline-flex;
  width: 100px !important;
  height: 100px;

  img {
    width: 100%;
    height: 100px;
    object-fit:contain;
  }

  .imageDelete {
    position: absolute;
    top: 5px;
    right: -20px;
    background-color: rgb(218, 218, 218);
    outline: none;
    border-radius: 30px;
    border: none;
    cursor: pointer;
  }
}


}

