.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 280px; 
  background-color: #2c3e50; 
  color: #ecf0f1; 
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transition: width 0.3s;

  .toggleIcon {
    cursor: pointer;
    font-size: 24px; 
    padding: 10px; 
    color: #ecf0f1;
    text-align: right;
    transition: color 0.3s;

    &:hover {
      color: #e74c3c; 
    }
  }

  &.collapsed {
    width: 80px;
    justify-content: center; 
    padding: 10px 0; 

    .toggleIcon {
      position: absolute;
      right: 10px; 
    }

    
    .sidebar-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px; 
      margin-bottom: 10px;

      img {
        width: 40px; 
        height: auto; 
      }
    }

    a {
      display: none; 
    }
    
    .tooltip {
      position: absolute;
      left: 80px; 
      top: 50%;
      transform: translateY(-50%);
      background-color: #e74c3c; 
      color: #fff; 
      padding: 5px 10px;
      border-radius: 4px;
      visibility: hidden; 
      opacity: 0; 
      transition: visibility 0s, opacity 0.3s; 

      &.visible {
        visibility: visible; 
        opacity: 1; 
      }
    }
  }

  a {
    display: block;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #ecf0f1;
    text-decoration: none;
    border-radius: 4px;
    margin: 5px 0;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2); 
    }

    &.selectedLink {
      background-color: rgba(231, 76, 60, 0.3); 
    }
  }

  button {
    font-size: 16px;
    font-weight: 600;
    color: #ecf0f1;
    background-color: #fa5745;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    margin-top: auto;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #c0392b; 
    }
  }
}