.login {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .errorMessage {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    :global {
      .ant-alert-message {
        font-size: 20px;
      }
    }
  }
  .loginWrapper {
    max-width: 500px;
    width: 100%;
    padding: 25px 15px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(#000, 0.16);
    border-radius: 6px;
    h1 {
      text-align: center;
      margin: 10px 0;
      font-size: 36px;
      font-weight: 700;
    }

    .formItem {
      margin-bottom: 15px;
      position: relative;

      > button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        padding-right: 8px;
        background-color: transparent;
      }
      input {
        border-radius: 3px;
        border: 1px solid rgba(#1A2028, 0.4);
        height: 40px;
        width: 100%;
        padding: 0 45px 0 17px;
        outline: none;

      }
    }
    .formInvalid {
      input {
        border-color: #ff0000;
      }
      span {
        color: #ff0000;
      }
      span {
        position: relative;
        top: -15px;
      }
    }
    label {
      font-weight: 600;
      display: block;
      font-size: 16px;
      margin-bottom: 4px;
      color: rgba(#1A2028, 0.4);;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 6px;
      padding: 15px 20px;
      margin-left: auto;
      outline: none;
      background-color: #1A2028;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
